import "./App.css";
import "antd/dist/antd.css";
import MainPage from "./container/MainPage";
import React, { useState } from "react";
import { Form, Input, Button,Spin } from "antd";
function App() {
  const [pass, setPass] = useState("");
  const [loggedIn, setIsLoggedIn] = useState(false);
  const [loading, setIsloading] = useState(false);
  const pCheck = async () => {
    setIsloading(true);
    const response = await fetch(
      "https://dr11-9d1df-default-rtdb.firebaseio.com/pass.json"
    );
    const data = await response.json();
    if (data == pass) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
    setIsloading(false);
  };

  return (
    <div className="App main-content ">
              <Spin spinning={loading}>
{!loggedIn && (
        <div className="main-container ">
          <Form.Item
            //   name="match_no"
            rules={[
              {
                required: true,
                whitespace: true,
                message: "Please enter product name",
              },
            ]}
          >
            <Input.Password
              className="input"
              value={pass}
              placeholder="Enter password"
              onChange={(e) => {
                setPass(e.target.value);
              }}
            />
          </Form.Item>
          <Button onClick={pCheck}>submit</Button>
        </div>
      )}

      {loggedIn && (
          <MainPage />
        
      )}
      </Spin>
    </div>
  );
}

export default App;
