import React, { useEffect } from "react";
import { Table } from "antd";

const DarshanData = (props) => {
  const { ds, np, dr } = props.totals;
  const collum = [
    {
      title: "To Dhvani",
      dataIndex: "toDhvani",
      key: "ds",
    },
    {
      title: "To Nikhil",
      dataIndex: "toNikhil",
      key: "np",
    },
  ];
  const data = [
    {
      toNikhil: (np - dr) * 10,
      toDhvani: (ds - dr) * 10,
    },
  ];
  return (
    <>
      <Table columns={collum} dataSource={data} pagination={false} />
    </>
  );
};

export default DarshanData;
