import React, { useState } from "react";
import {
  Form,
  Input,
  Modal,
  Row,
  Col,
  Button,
  Select,
  InputNumber,
} from "antd";
const { Option } = Select;
const FormInput = (props) => {
  const [match, setMatch] = useState();
  const [winner, setWinner] = useState();
  const form = Form.useForm();
  const handleSubmit = async () => {
    // https://dr11-9d1df-default-rtdb.firebaseio.com/matchData
    if (match && winner) {
      const data = { no: match ,date:new Date().toLocaleDateString("en-IN")};
      const res = await fetch(
        `https://dr11-9d1df-default-rtdb.firebaseio.com/${winner}.json`,
        {
          method: "POST",
          body: JSON.stringify(data),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const d = await res.json();
      setWinner();
      setMatch();
      props.updateData();
    } else {
      console.log("data undifined");
    }
  };
  return (
    <div>
      {/* <Form>
            <Form.Item>
                <Input/>
            </Form.Item>
        </Form> */}
      <Form
        // form={form}
        layout={"vertical"}
        // name="productForm"
        onFinish={handleSubmit}
        // onFinishFailed={onFinishFailed}
        onKeyPress={(event) => {
          if (event.which === (13 | "Enter")) {
            event.preventDefault();
          }
        }}
      >
        <Row gutter={30} type="flex">
          <Col md={12} sm={24}>
            <Form.Item
              //   name="match_no"
              label="Match No."
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: "Please enter product name",
                },
              ]}
            >
              <Input
                className="input"
                value={match}
                placeholder="Enter Match Name"
                onChange={(e) => {
                  setMatch(e.target.value);
                }}
              />
            </Form.Item>
          </Col>
          <Col md={12} sm={24}>
            <Form.Item
              //   name="winner_name"
              label="Winner Name"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: "Please enter winnner name",
                },
              ]}
            >
              <Select
                value={winner}
                placeholder="Select Winner Name"
                onSelect={(value) => {
                  setWinner(value);
                }}
              >
                <Option value="darshan">Darshan</Option>
                <Option value="dhvani">Dhvani</Option>
                <Option value="nikhil">Nikhil</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row
          gutter={30}
          type="flex"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button htmlType="submit">Add Data</Button>
        </Row>
      </Form>
    </div>
  );
};

export default FormInput;
