import React, { useEffect, useState } from "react";
import { Button, Collapse, Table } from "antd";
import DarshanData from "./DarshanData";
import DhvaniData from "./DhvaniData";
import NikhilData from "./NikhilData";
const { Panel } = Collapse;

const Results = (props) => {
  const [tableDisplay, settableDisplay] = useState(false);
  const columns = [
    {
      title: "Match No.",
      dataIndex: "no",
      key: "no",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Winner Name.",
      key: "name",
      render: (record) => {
        return <p>{record.name}</p>;
      },
    },
  ];

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <p>Results</p>
        {
          !tableDisplay ?
          <>
            <Button
              onClick={() => {
                settableDisplay(true);
              }}
            >
              View History
            </Button></> :
            <>
            <Button
              onClick={() => {
                settableDisplay(false);
              }}
            >
              Back
            </Button>
          </>
        }
      </div>

      {!tableDisplay && (
        <>
          <Collapse accordion>
            <Panel
              header={
                <>
                  <div className="total-win">
                    <p>Darshan</p>
                    <div>Won: {props.totals?.dr}</div>
                  </div>
                </>
              }
              key="1"
            >
              <DarshanData totals={props.totals} />
            </Panel>
            <Panel
              header={
                <>
                  <div className="total-win">
                    <p>Dhvani</p>
                    <div>Won: {props.totals?.ds}</div>
                  </div>
                </>
              }
              key="2"
            >
              <DhvaniData totals={props.totals} />
            </Panel>
            <Panel
              header={
                <>
                  <div className="total-win">
                    <p>Nikhil</p>
                    <div>Won: {props.totals?.np}</div>
                  </div>
                </>
              }
              key="3"
            >
              <NikhilData totals={props.totals} />
            </Panel>
          </Collapse>
        </>
      )}

      {tableDisplay && (
        <>
          <Table
            columns={columns}
            dataSource={props.allData}
            pagination={false}
            scroll={400}
          />
        </>
      )}
    </div>
  );
};

export default Results;
