import React, { useEffect, useState } from "react";
import FormInput from "./FormInput";
import Results from "./Results";

const MainPage = () => {
  const [totals, setTotals] = useState({ ds: 0, dr: 0, np: 0 });
  const [dsDataArray, setDsData] = useState([]);
  const [drDataArray, setDrData] = useState([]);
  const [npDataArray, setNpData] = useState([]);
  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    const fetchDS = async () => {
      const response = await fetch(
        "https://dr11-9d1df-default-rtdb.firebaseio.com/dhvani.json"
      );
      const dsData = await response.json();

      setDsData(obToArray(dsData,"Dhvani"));
    
      setTotals((prevState) => ({
        ...prevState,
        ds: dsData ? Object.keys(dsData).length : 0,
      }));
    };
    fetchDS();
    const fetchDR = async () => {
      const response = await fetch(
        "https://dr11-9d1df-default-rtdb.firebaseio.com/darshan.json"
      );
      const drData = await response.json();
      setDrData(obToArray(drData,"Darshan"));
      setTotals((prevState) => ({
        ...prevState,
        dr: drData ? Object.keys(drData).length : 0,
      }));
    };
    fetchDR();
    const fetchNP = async () => {
      const response = await fetch(
        "https://dr11-9d1df-default-rtdb.firebaseio.com/nikhil.json"
      );
      const npData = await response.json();
      setNpData(obToArray(npData,"Nikhil"));
      setTotals((prevState) => ({
        ...prevState,
        np: npData ? Object.keys(npData).length : 0,
      }));
    };
    fetchNP();
  };
  const obToArray = (obj={},name="") => {
    let array = [];
    Object.keys(obj).forEach((key) => {
      array.push({...obj[key],name});
    });
    return array;
  };
  return (
    <div className="main-container">
      <div className="form-container">
        <FormInput updateData={getData} />
      </div>
      <div className="result-container">
        <Results
          totals={totals}
          allData={[...dsDataArray,...drDataArray,...npDataArray]}
          
        />
      </div>
    </div>
  );
};
export default MainPage;
