import React, { useEffect } from "react";
import { Table } from "antd";

const NikhilData = (props) => {
  const { ds, np, dr } = props.totals;
  const collum = [
    {
      title: "To Dhvani",
      dataIndex: "toDhvani",
      key: "ds",
    },
    {
      title: "To Darshan",
      dataIndex: "toDarshan",
      key: "dr",
    },
  ];
  const data = [
    {
      toDhvani: (ds - np) * 10,
      toDarshan: (dr - np) * 10,
    },
  ];
  return (
    <>
      <Table columns={collum} dataSource={data} pagination={false} />
    </>
  );
};

export default NikhilData;
